import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { OptanonButton } from '../atoms/OptanonButton';

export const CookieDisclaimer = ({ message }) => {
  const style = {
    wrapper: css`
      display: flex;
      align-items: baseline;
      justify-content: center;
      border: solid 4px #000;
      padding: 15px;
      margin-bottom: 15px;
      p {
        margin: 0;
      }
    `,
    button: css`
      display: flex;
      justify-content: center;
      margin-left: 1em;
    `
  };

  return (
    <div css={style.wrapper}>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <div css={style.button}>
        <OptanonButton color="#333" isLink />
      </div>
    </div>
  );
};

CookieDisclaimer.propTypes = {
  message: PropTypes.string.isRequired
};

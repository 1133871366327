import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const InlineTextLoadable = loadable(() => import('./InlineText'), {
  resolveComponent: components => components.InlineText
});

export const InlineText = WithLazyHydration(InlineTextLoadable, {
  name: 'InlineText'
});

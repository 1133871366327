import { removeEmptyProperties } from '../../../utils/objectUtils';
import { conf } from '../../configService';
import { imageObjectType } from './imageObjectType';
import { listItemType } from './listItemType';
import { organizationType } from './organizationType';
import { getVideoSchemaDataFromCard, videoObjectType } from './videoObjectType';

const imageGalleryType = card => {
  const {
    title: name,
    date: datePublished,
    date: dateModified,
    url,
    imageEntity
  } = card;

  return removeEmptyProperties({
    '@type': 'ImageGallery',
    '@id': `https://${conf.canonicalDomain}${url}`,
    name,
    url: `https://${conf.canonicalDomain}${url}`,
    datePublished,
    dateModified,
    publisher: organizationType(),
    primaryImageOfPage: imageObjectType(imageEntity)
  });
};

const articleType = card => {
  const {
    title: headline,
    date: datePublished,
    date: dateModified,
    url,
    imageEntity
  } = card;

  return removeEmptyProperties({
    '@type': 'Article',
    headline,
    // name,
    datePublished,
    dateModified,
    publisher: organizationType(),
    // author: personType(conf.siteName),
    mainEntityOfPage: `https://${conf.canonicalDomain}${url}`,
    url: `https://${conf.canonicalDomain}${url}`,
    image: imageObjectType(imageEntity)
  });
};

const getCardSchema = card => {
  switch (card.type) {
    case 'video':
      return videoObjectType(getVideoSchemaDataFromCard(card));
    case 'photo_gallery':
      return imageGalleryType(card);
    case 'article':
      return articleType(card);
    default:
      return null;
  }
};

// @see https://schema.org/itemList
export const itemListType = (content, cardData, page) => {
  const currentPage = typeof page === 'undefined' && 1;
  const listItems = cardData.map((card, index) =>
    listItemType(
      index + 1,
      card.props.data.title,
      getCardSchema(card.props.data)
    )
  );

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    name: `${content.title} - Page ${currentPage}`,
    url: `https://${conf.canonicalDomain}${content.url.path}${
      typeof page === 'undefined' ? '' : `?page=${page}`
    }`,
    itemListElement: listItems
  };
};

import { useState, useEffect } from 'react';
import { consentService } from '../domain/services/consentService';
import { eventService } from '../domain/services/eventService';

export const useCmpSettings = () => {
  const [cmpSettings, setCmpSettings] = useState(null);

  const handleRequirementsReady = () => {
    setCmpSettings(consentService.getCmpSettings());
  };

  useEffect(() => {
    // Set an event listener without "once", to listen to changes
    // to CMP without reloading the page.
    eventService.addEventListener(
      'OneTrustInteractionDone',
      handleRequirementsReady
    );

    return () =>
      eventService.removeEventListener(
        'OneTrustInteractionDone',
        handleRequirementsReady
      );
  }, []);

  return cmpSettings;
};

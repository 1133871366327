import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const StandaloneVideoLoadable = loadable(() => import('./StandaloneVideo'), {
  resolveComponent: components => components.StandaloneVideo
});

export const StandaloneVideo = WithLazyHydration(StandaloneVideoLoadable, {
  name: 'StandaloneVideo'
});

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ContainerMain } from '../responsive/atoms/ContainerMain';
import { theme } from '../../domain/theme';
import { CardGridLeftRightWithAdLegacy } from '../responsive/organisms/CardGridLeftRightWithAdLegacy';

const styles = {
  main: css`
    margin-top: ${theme.spacing('xs')};
  `
};
export const TermViewExplorers = ({ content, data }) => {
  let cardItems = [];
  if (data.length > 1) {
    cardItems = data.map(entity => ({
      data: entity.props.data,
      isAboveTheFold: entity.isAboveTheFold
    }));
  }
  return (
    <div css={styles.main}>
      <ContainerMain>
        {cardItems.length > 1 && (
          <div>
            <CardGridLeftRightWithAdLegacy
              cardItems={cardItems}
              content={content}
            />
          </div>
        )}
      </ContainerMain>
    </div>
  );
};

TermViewExplorers.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const RelatedHubPagesLoadable = loadable(() => import('./RelatedHubPages'), {
  resolveComponent: components => components.RelatedHubPages
});

export const RelatedHubPages = WithLazyHydration(RelatedHubPagesLoadable, {
  name: 'RelatedHubPages',
  minHeight: 63
});

import loadable from '@loadable/component';
import { WithLazyHydration } from '../WithLazyHydration';

const SponsoredAdditionalTextLoadable = loadable(
  () => import('./SponsoredAdditionalText'),
  {
    resolveComponent: components => components.SponsoredAdditionalText
  }
);

export const SponsoredAdditionalText = WithLazyHydration(
  SponsoredAdditionalTextLoadable,
  {
    name: 'SponsoredAdditionalText',
    minHeight: 118
  }
);

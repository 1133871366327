import React from 'react';
import { WithIcon } from './WithIcon';

export const IconUp = WithIcon(
  'Up',
  '0 0 1024 1024',
  <path
    transform="scale(1, -1) translate(0, -960)"
    d="M556-18v815.4l304.8-303.2 42.4 42.6-379.2 377.2-375.2-377.4 42.6-42.2 304.6 306.4v-818.8z"
  />
);

import React from 'react';
import { useCmpSettings } from '../hooks/useCmpSettings';

/**
 * A High Order Component (HOC) that renders the component passed in props
 * with a cmpSettings props coming from consentService.
 *
 * This HOC, unlike others, does not block the render of the child component
 * until it gets cmpSettings from consentService. Instead, it re-renders it once
 * it gets that data.
 *
 * @see WithCmpSettingsBlocking for a blocking version of this HOC
 *
 * @param {Component} Component - A React component
 * @returns Component - A newly create React component over the one passed in props
 */
export const WithCmpSettingsNonBlocking = Component => {
  return props => {
    const cmpSettings = useCmpSettings();
    return <Component cmpSettings={cmpSettings} {...props} />;
  };
};

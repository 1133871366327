import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const TravelGuideBreadcrumbsParagraphLoadable = loadable(
  () => import('./TravelGuideBreadcrumbsParagraph'),
  {
    resolveComponent: components => components.TravelGuideBreadcrumbsParagraph
  }
);

export const TravelGuideBreadcrumbsParagraph = WithLazyHydration(
  TravelGuideBreadcrumbsParagraphLoadable,
  {
    name: 'TravelGuideBreadcrumbsParagraph'
  }
);

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { css } from '@emotion/react';
import { theme } from '../../domain/theme';
import { t } from '../../domain/services/configService';
import { queryService } from '../../domain/services/queryService';
import TermView from './TermView';
import { resourceService } from '../../domain/services/resourceService';
import {
  isElementVisible,
  isBottomEdgeInViewport
} from '../../domain/utils/domUtils';
import { calculatePageTitle } from '../../views/HubPage/utils/calculatePageTitle';
import { Loading } from '../responsive/atoms/Loading';
import { Button } from '../responsive/atoms/Button';
import { debounce } from '../../domain/utils/debounce';
import {
  mapContentToCardData,
  removeAuthorsAndDateFromCardData
} from '../../domain/utils/contentUtils';
import { IconUp } from '../Icon/IconUp';
// import { sleep } from '../../../domain/utils/sleep';

const styles = {
  loader: css`
    padding: ${theme.spacing.parse('$xl 0')};
  `
};

let currentVisibleBlockIds = [];

const getCurrentPage = () =>
  parseInt(queryService.getParameter('page') || 1, 10);

let previousScrollYCoordinate = getCurrentPage() === 1 ? 0 : 100;

const getCards = async (path, index, staticProps) => {
  return resourceService.getJsonForPathname(path).then(json => {
    const cardProps = {
      data: removeAuthorsAndDateFromCardData(mapContentToCardData(json)),
      size: staticProps[index].size,
      aspect: staticProps[index].aspect,
      excludeImage: false // staticProps[index].excludeImage
    };
    return { component: 'CardItem', props: cardProps };
  });
};

export const InfiniteScroll = ({ content, onPaginationChange }) => {
  const containerRef = useRef(null);

  const [state, setState] = useState(() => ({
    loading: false,
    loadingPosition: null,
    isFirstPaginationVisible: true,
    pages: {}
  }));

  const updateTitle = page => {
    document.title = calculatePageTitle(page);
  };

  const loadPagination = async (page, position) => {
    // content.termContent.data?.pagination starts with 0, but our first
    // dynamic pagination is 2, since 1 is the first one, rendered during
    // build step.
    if (
      content.termContent?.data?.pagination &&
      content.termContent?.data?.pagination[page - 2]
    ) {
      if (!state.loading) {
        setState(prevState => ({
          ...prevState,
          loading: true,
          loadingPosition: position
        }));
      }
      const paginationItems = content.termContent.data?.pagination[page - 2];
      const staticProps = content.termContent.data?.staticProps;
      const newItems = await Promise.all(
        paginationItems.map((item, index) => {
          return getCards(item[1], index, staticProps);
        })
      );

      setState(prevState => {
        const { pages } = prevState;
        pages[page] = newItems;
        return {
          ...prevState,
          loading: false,
          loadingPosition: null,
          pages
        };
      });
    }
  };

  const conditionallyLoadMoreContent = async () => {
    const isGoingUp = previousScrollYCoordinate > window.scrollY;
    const isGoingDown = !isGoingUp;
    previousScrollYCoordinate = window.scrollY;

    const currentPage = getCurrentPage();

    const scrolledToBottom = isBottomEdgeInViewport(containerRef.current, 500);
    if (isGoingDown && scrolledToBottom && !state.loading) {
      const newPage = currentPage + 1;
      if (!state.pages[newPage]) {
        await loadPagination(newPage, 'bottom');
      }
    }
  };

  const updateCurrentPage = () => {
    const allTermViewBlocks = Array.from(
      containerRef.current.querySelectorAll('section[data-page]')
    );
    if (allTermViewBlocks.length > 0) {
      const visibleBlockIds = [];
      allTermViewBlocks.forEach(block => {
        if (isElementVisible(block)) {
          visibleBlockIds.push(block.dataset.page);
        }
      });
      if (visibleBlockIds.length > 0) {
        const currentPage = getCurrentPage();
        for (let i = 0; i < visibleBlockIds.length; i += 1) {
          if (
            visibleBlockIds[i] !== currentPage &&
            visibleBlockIds.join('-') !== currentVisibleBlockIds.join('-')
          ) {
            currentVisibleBlockIds = visibleBlockIds;
            const newPage = visibleBlockIds[i];
            queryService.updateParameters({ page: newPage });
            if (onPaginationChange) {
              onPaginationChange(newPage);
            }
            updateTitle(newPage);
            break;
          }
        }
      } else {
        currentVisibleBlockIds = visibleBlockIds;
        if (state.isFirstPaginationVisible) {
          queryService.updateParameters({ page: null });
          if (onPaginationChange) {
            onPaginationChange(1);
          }
          updateTitle(null);
        }
      }
    }
  };

  const loadPreviousContents = async () => {
    const currentPage = getCurrentPage();
    const newPage = currentPage - 1;

    // Disable scrolling while adding new elements to the DOM that will alter that scroll.
    document.body.style.height = '100vh !important';
    document.body.style.overflow = 'hidden';

    // Save current scroll point.
    const previousPageSection = containerRef.current.querySelector(
      `section[data-page="${currentPage}"]`
    );
    const previousPageSectionTop = previousPageSection
      ? previousPageSection.getBoundingClientRect().top
      : 0;

    if (newPage === 1) {
      // Even though an event doesn't need "await", and some linters could consider it redundant,
      // in fact it is required to be able to wait for the event to be done. If not using "await", the
      // scroll point restoration that appears below doesn't have any affect, because it is executed before
      // the first page is rendered into the DOM.
      await window.dispatchEvent(
        new CustomEvent('hub-page-first-page-reached')
      );
      setState(prevState => ({ ...prevState, isFirstPaginationVisible: true }));
    } else {
      await loadPagination(newPage, 'top');
    }

    // Restore scroll point.
    if (previousPageSection) {
      window.scrollTo(
        0,
        Math.abs(
          previousPageSectionTop -
            previousPageSection.getBoundingClientRect().top
        )
      );
    }

    // Enable scrolling.
    document.body.style.height = 'auto';
    document.body.style.overflow = 'visible';
  };

  const checkFirstPageVisibility = () => {
    const firstPageElement = document.getElementById(
      'hub-page-first-page-content'
    );
    if (firstPageElement && isElementVisible(firstPageElement)) {
      setState(prevState => ({
        ...prevState,
        isFirstPaginationVisible: true
      }));
    }
  };

  const handleScroll = debounce(async () => {
    checkFirstPageVisibility();
    await conditionallyLoadMoreContent();
    updateCurrentPage();
  }, 50);

  useEffect(() => {
    const currentPage = getCurrentPage();
    if (currentPage !== 1) {
      loadPagination(currentPage, 'bottom').then(() => {
        updateTitle(currentPage);
        if (onPaginationChange) {
          onPaginationChange(currentPage);
        }
      });
    }
    if (!document.getElementById('hub-page-first-page-content')) {
      setState(prevState => ({
        ...prevState,
        isFirstPaginationVisible: false
      }));
    }
  }, []);

  // This useEffect needs state to be passed as a dependency, so the closure
  // around handleScroll() gets the updated value.
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [state]);

  const pageTitle =
    typeof document !== 'undefined'
      ? calculatePageTitle(getCurrentPage())
      : null;
  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <div ref={containerRef}>
        {state.loading === true && state.loadingPosition === 'top' && (
          <div css={styles.loader}>
            <Loading />
          </div>
        )}

        {!state.isFirstPaginationVisible && (
          <Button onClick={loadPreviousContents} padding="xs" fullWidth>
            <IconUp size={16} />
            {` ${t('Read More')} `}
            <IconUp size={16} />
          </Button>
        )}

        {Object.keys(state.pages).length > 0 &&
          Object.keys(state.pages).map(page => {
            return (
              <section data-page={page} key={`card_${page}`}>
                <TermView
                  data={state.pages[page]}
                  page={parseInt(page, 10)}
                  content={content}
                />
              </section>
            );
          })}

        {state.loading === true && state.loadingPosition === 'bottom' && (
          <div css={styles.loader}>
            <Loading />
          </div>
        )}
      </div>
    </>
  );
};

InfiniteScroll.defaultProps = {
  onPaginationChange: undefined
};

InfiniteScroll.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  onPaginationChange: PropTypes.func
};

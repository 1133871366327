import { t } from '../../../domain/services/configService';

export const calculatePageTitle = page => {
  const titleParts = document.title.split(' | ');
  const titleFirstPart = titleParts[0];
  const titleLastPart = titleParts.slice(-1)[0];
  if (page && page !== 1) {
    return `${titleFirstPart} | ${t('Page')} ${page} | ${titleLastPart}`;
  }
  return `${titleFirstPart} | ${titleLastPart}`;
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { conf } from '../../../../domain/services/configService';
import { addScript } from '../../../../domain/utils/domUtils';

export const SweepstakesContainer = ({ campaignId }) => {
  useEffect(() => {
    addScript(conf.sweepstakes_js_url);
  }, []);
  return <div className="chimney" data-campaign-id={campaignId} />;
};

SweepstakesContainer.propTypes = {
  campaignId: PropTypes.string.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { consentService } from '../../../domain/services/consentService';
import { WithCmpSettingsNonBlocking } from '../../WithCmpSettingsNonBlocking';
import { CookieDisclaimer } from '../molecules/CookieDisclaimer';

const CookieConsentInner = ({
  cmpSettings,
  cookieType,
  fallback,
  children
}) => {
  if (cookieType) {
    if (cmpSettings) {
      if (consentService.getCookiePermissions(cookieType, cmpSettings)) {
        return children;
      }
      return <CookieDisclaimer message={fallback} />;
    }
    return null;
  }

  return children;
};

CookieConsentInner.defaultProps = {
  cmpSettings: undefined,
  cookieType: undefined,
  fallback: undefined
};

CookieConsentInner.propTypes = {
  cmpSettings: PropTypes.objectOf(PropTypes.any),
  cookieType: PropTypes.string,
  fallback: PropTypes.string,
  children: PropTypes.node.isRequired
};

export const CookieConsent = WithCmpSettingsNonBlocking(CookieConsentInner);

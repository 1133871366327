import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const TravelGuideCountryListParagraphLoadable = loadable(
  () => import('./TravelGuideCountryListParagraph'),
  {
    resolveComponent: components => components.TravelGuideCountryListParagraph
  }
);

export const TravelGuideCountryListParagraph = WithLazyHydration(
  TravelGuideCountryListParagraphLoadable,
  {
    name: 'TravelGuideCountryListParagraph'
  }
);

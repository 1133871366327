import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Ad } from '../responsive/atoms/Ad';
import { LeftAndRightContentPackage } from '../Paragraph/LeftAndRightContentPackage';
import { facePaint } from '../../domain/theme/utils/facePaint';
import { CardItemVerticalLegacy } from '../responsive/organisms/CardItemVerticalLegacy';
import { ContentPackageWrapper } from '../responsive/organisms/ContentPackageWrapper';
import { ContainerMain } from '../responsive/atoms/ContainerMain';
import { GridColumn, GridRow } from '../responsive/atoms/Grid';
import { theme } from '../../domain/theme';
import { GridLeftRight } from '../responsive/organisms/GridLeftRight';
import { GridColumnGroup } from '../responsive/atoms/Grid/GridColumnGroup';

const hubPageAdSquare = css`
  display: block;
  margin-left: -30px;
  margin-top: 30px;
  text-align: center;
  width: 100%;

  ${theme.mq.tablet} {
    & {
      display: none;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      display: inline-block;
      margin: 30px auto 60px;
    }
  }
`;

const hubPageAdTablet = css`
  display: none;

  ${theme.mq.tablet} {
    & {
      display: block;
      margin-top: 10px;
      text-align: center;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      display: none;
    }
  }
`;

const hubPageStickyColumn = css`
  position: sticky;
  top: 0;
`;

export const TermViewHubpage = ({ content, data, isAboveTheFold }) => {
  const threeUp = data.slice(0, 2);
  const grid = data.slice(2, 10);
  const leftRight = data.slice(10, 18);
  let leftAndRightContentPackage = {};
  if (leftRight.length >= 8) {
    leftAndRightContentPackage = {
      type: 'ParagraphLeftAndRightContentPackage',
      id: 'auto',
      backgroundColor: 'black',
      leftContentPackage: {
        entity: {
          prominentPosition: leftRight[0],
          contentList: leftRight.slice(1, 5)
        }
      },
      rightContentPackage: {
        entity: {
          mediumContents: leftRight[5],
          smallContents: leftRight.slice(6, 8)
        }
      }
    };
  }
  const adId = data[0].props.data.id;

  const gridContents = (
    <GridRow gutter="base" key="GridRow-2">
      {grid.map((currentComponent, index) => {
        const component = currentComponent;
        component.props.backgroundColor = content.backgroundColor
          ? content.backgroundColor
          : component.props.backgroundColor;
        if (index === 5) {
          return (
            <GridColumnGroup
              key={`GridColumnGroup-${component.props.data.url}`}
            >
              <GridColumn
                width={[12, 12 / 2, 0]}
                key={`GridColumn-${component.props.data.url}-1`}
              >
                <Ad id={`hub_page_embed_grid__${adId}`} content={content} />
              </GridColumn>
              <GridColumn
                width={[12, 12 / 2]}
                key={`GridColumn-${component.props.data.url}-2`}
              >
                <CardItemVerticalLegacy
                  {...component.props}
                  imageMaxWidths={{
                    mobile: 703,
                    tablet: 456,
                    small_desktop: 394,
                    large_desktop: 546
                  }}
                />
              </GridColumn>
            </GridColumnGroup>
          );
        }
        return (
          <GridColumn
            width={[12, 12 / 2]}
            key={`GridColumn-${component.props.data.url}`}
          >
            <CardItemVerticalLegacy
              {...component.props}
              imageMaxWidths={{
                mobile: 703,
                tablet: 456,
                small_desktop: 394,
                large_desktop: 546
              }}
            />
          </GridColumn>
        );
      })}
    </GridRow>
  );
  const contentBackgroundColorOrBlack = content.backgroundColor
    ? content.backgroundColor
    : 'black';
  const contentBackgroundColorOrWhite = content.backgroundColor
    ? content.backgroundColor
    : 'white';
  return (
    <>
      {threeUp[0] && threeUp[1] && (
        <ContentPackageWrapper
          className="module--type-ThreeUp module--id-auto"
          backgroundColor={
            content.backgroundColor === 'black'
              ? 'true_black'
              : contentBackgroundColorOrBlack
          }
          extraStyles={css`
            padding-bottom: 40px;
            ${theme.mq.tablet} {
              padding-bottom: 40px;
            }
            ${theme.mq.small_desktop} {
              padding-top: 118px;
            }
            ${facePaint({
              'contain-intrinsic-size': [
                '0 4586px',
                '0 2082px',
                '0 1606px',
                '0 1817px'
              ]
            })};
          `}
        >
          <ContainerMain>
            <GridRow gutter={[null, null, null, 'l']}>
              <GridColumn width={[12, 7, 7, 7]} key={threeUp[0].props.data.id}>
                <CardItemVerticalLegacy
                  isAboveTheFold={isAboveTheFold}
                  {...threeUp[0].props}
                  backgroundColor={contentBackgroundColorOrBlack}
                  imageMaxWidths={{
                    mobile: 703,
                    tablet: 537,
                    small_desktop: 658,
                    large_desktop: 839
                  }}
                />
              </GridColumn>
              <GridColumn width={[12, 5, 5, 5]} key={threeUp[1].props.data.id}>
                <CardItemVerticalLegacy
                  {...threeUp[1].props}
                  backgroundColor={contentBackgroundColorOrBlack}
                  imageMaxWidths={{
                    mobile: 703,
                    tablet: 374,
                    small_desktop: 461,
                    large_desktop: 585
                  }}
                />

                <div css={hubPageAdSquare}>
                  <Ad id={`three_up_package__${adId}`} content={content} />
                </div>
              </GridColumn>
            </GridRow>
          </ContainerMain>
        </ContentPackageWrapper>
      )}
      {grid.length > 0 && (
        <div>
          <div css={hubPageAdTablet}>
            <Ad id={`hub_page_embed__${adId}`} content={content} />
          </div>
          <ContentPackageWrapper
            className="module--type-Grid module--id-auto"
            backgroundColor={
              content.backgroundColor === 'black'
                ? 'true_black'
                : contentBackgroundColorOrWhite
            }
            extraStyles={css`
              ${facePaint({
                'contain-intrinsic-size': [
                  '0 4586px',
                  '0 2082px',
                  '0 1606px',
                  '0 1817px'
                ]
              })}
            `}
          >
            <ContainerMain>
              <GridLeftRight
                left={[gridContents]}
                right={[
                  <div
                    css={hubPageStickyColumn}
                    key="TermViewHubPage-hubPageStickyColumn"
                  >
                    <Ad
                      id={`hub_page_embed_sticky__${adId}`}
                      content={content}
                      key={`hub_page_embed_sticky__${adId}`}
                    />
                  </div>
                ]}
              />
            </ContainerMain>
          </ContentPackageWrapper>
        </div>
      )}
      {leftRight.length >= 8 && (
        <LeftAndRightContentPackage
          data={leftAndRightContentPackage}
          content={content}
        />
      )}
    </>
  );
};

TermViewHubpage.defaultProps = {
  isAboveTheFold: false
};

TermViewHubpage.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isAboveTheFold: PropTypes.bool
};

import PropTypes from 'prop-types';

export const GridColumnGroup = ({ children }) => {
  if (process.env.NODE_ENV === 'development') {
    if (children) {
      const childrenArray = Array.isArray(children) ? children : [children];
      childrenArray.forEach(child => {
        if (child !== null && child.type?.name !== 'GridColumn') {
          throw Error(
            `Prop 'children' in 'GridColumnGroup' must be a group of 'GridColumn' components. Found '${child.type.name}`
          );
        }
      });
    } else {
      throw Error(`Prop 'children' can not be empty in 'GridColumnGroup'`);
    }
  }

  return children;
};

GridColumnGroup.propTypes = {
  children: PropTypes.node.isRequired
};

import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const CallToActionButtonLoadable = loadable(
  () => import('./CallToActionButton'),
  {
    resolveComponent: components => components.CallToActionButton
  }
);

export const CallToActionButton = WithLazyHydration(
  CallToActionButtonLoadable,
  {
    name: 'CallToActionButton'
  }
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { itemListType } from '../../domain/services/schemaService/types/itemListType';
import { TermViewExplorers } from './TermViewExplorers';
import { TermViewHubpage } from './TermViewHubpage';

const TermView = ({ content, data, isAboveTheFold, page }) => {
  useEffect(() => {
    const hubPagination = document.getElementsByClassName('hubPagination');
    if (hubPagination.length > 1) {
      Array.from(hubPagination).forEach(item => {
        if (item.id !== `hubpage_${page}`) {
          item.remove();
        }
      });
    }
  }, []);

  return (
    <section key={`section-${content.id}`}>
      <Helmet>
        <script
          className="hubPagination"
          id={`hubpage_${page}`}
          type="application/ld+json"
        >
          {JSON.stringify(itemListType(content, data, page))}
        </script>
      </Helmet>
      {content.primaryTaxonomy?.entity?.vocabulary?.id === 'explorers' ? (
        <TermViewExplorers content={content} data={data} />
      ) : (
        <TermViewHubpage
          content={content}
          data={data}
          isAboveTheFold={isAboveTheFold}
        />
      )}
    </section>
  );
};

TermView.defaultProps = {
  isAboveTheFold: false,
  page: undefined
};

TermView.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isAboveTheFold: PropTypes.bool,
  page: PropTypes.number
};

export default TermView;

import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const TravelGuideSearchBoxParagraphLoadable = loadable(
  () => import('./TravelGuideSearchBoxParagraph'),
  {
    resolveComponent: components => components.TravelGuideSearchBoxParagraph
  }
);

export const TravelGuideSearchBoxParagraph = WithLazyHydration(
  TravelGuideSearchBoxParagraphLoadable,
  {
    name: 'TravelGuideSearchBoxParagraph'
  }
);

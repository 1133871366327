import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const ImmersiveLeadLoadable = loadable(() => import('./ImmersiveLead'), {
  resolveComponent: components => components.ImmersiveLead
});

export const ImmersiveLead = WithLazyHydration(ImmersiveLeadLoadable, {
  name: 'ImmersiveLead'
});

import React from 'react';
import PropTypes from 'prop-types';
import { CookieConsent } from '../../organisms/CookieConsent';
import { SweepstakesContainer } from './SweepstakesContainer';
import { ParagraphWrapper } from '../../../Paragraph/ParagraphWrapper';

const SweepstakesInner = ({ data }) => {
  // Keep in mind that <SweepstakesInner/> needs to be a separate component, since it uses useEffect.
  return (
    <CookieConsent
      cookieType={data.cookieType}
      fallback={data.cookieTypeFallback}
    >
      <SweepstakesContainer campaignId={data.campaignId} />
    </CookieConsent>
  );
};

SweepstakesInner.propTypes = {
  data: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
    cookieType: PropTypes.string,
    cookieTypeFallback: PropTypes.string
  }).isRequired
};

export const Sweepstakes = ParagraphWrapper(SweepstakesInner);

import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const FocusedHeroPackageLoadable = loadable(
  () => import('./FocusedHeroPackage'),
  {
    resolveComponent: components => components.FocusedHeroPackage
  }
);

export const FocusedHeroPackage = WithLazyHydration(
  FocusedHeroPackageLoadable,
  {
    name: 'FocusedHeroPackage'
  }
);
